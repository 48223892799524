.card-table .swyched-spinner{
  color: #C1C5C8;
  font-size: 40px;
}
.access-control .card-body h2 {
  font-size: 1.938rem;
  color: #555;
  font-weight: 300;
  padding-bottom: 1.2em;
}

.card-table i.icon, i.icons {
  font-size: 1.2em;
}
.access-control-group-select {
  display: none;
  margin-bottom: 30px;
}