@import '../Variables.scss';

.pricing-plans-tab {
  padding-left: 0;
}
.integration .tab-control-container {
  margin-top: 0px;
}
.integration .tab-tab {
  padding: 5px;
  margin-right: 10px;
}
.integration .tab-control-tabs {
  box-shadow: none;
  background: none;
}
.integration .tab-tab span {
  top: 0;
  font-size: 1.375rem;
  
  margin-left: 0;
}
.integration .tab-tab.active-tab {
  background: none;
}
.integration .tab-tab.integration-account-tab {
  padding-left: 0;
}

.integration .tab-body {
  background: none;
  padding: 20px 0px;
}

.integration-cards .card-body, .integration-modal .card-body {
  padding: 1.875em;
}

.integration-modal .card {
  box-shadow: none;
  border: 1px solid rgb(193, 197, 200);
  border-radius: 5px;
}

.integration-settings {
  color: #C1C5C8;
  position: absolute;
  right: 25px;
  bottom: 25px;
  font-size: 1.3rem;
}

.integration-switch {
  position: absolute;
  right: 25px;
  top: 25px;
}

.integration-cards .card {
  min-height: 190px;
}

.integration-desc {
  font-size: 16px;
  color: #aaa;
  margin-top: 20px;
  line-height: 22px;
  padding-right: 35px;
}

h2.integration-header {
  margin-top: 3px;
  font-size: 23px;
}

.integration-icon {
  float: left;
  margin-right: 25px;
  width: 30px;
}
.integration-token-container {
  padding-top: 1.4em;
}
.integration-container h2{
  padding-bottom: 0.4em;
}

.integration-token-container span {
  font-size: 1rem;
  color: #555;
}
.integration-token-container label{
  margin-bottom: 0;
  color: #aaa;
  text-transform: uppercase;
  padding: 0;
  font-size: 13px;
}

.copy-api-key span {
  color: #C1C5C8;
  margin-left: 10px;
}

.page-description {
  font-size: 1.25rem;
  color: #aaa;
  line-height: 33px;
}
.page-description a {
  color: #3d607a;
  font-size: 1.2rem;
}

.row.integration-cards {
  clear: both;
  padding-top: 2em;
}