.connector-multiplier {
  font-size: 13px;
  color: #bbb;
  letter-spacing: 1px;
  line-height: 0px;
  top: 1px;
  left: 2px;
}
/* .devices-container .card-body {
  padding: 1.4em 1.4em;
} */

.add-device-icon {
  font-size: 1.4rem;
}

.site-rate-container label {
  padding-bottom: 0.3em;
  font-size: 0.75rem;
}

.site-container .card {
  margin-bottom: 52px;
}

.site-container .card-body {
  padding: 3.7em;
  padding-top: 2.3em;
  padding-bottom: 40px;
}

h2.site-title {
  font-size: 31px;
}

.site-header-container {
  padding-top: 3em;
  margin: 0em -0.925em;
}
.site-header-container:first-of-type {
  padding-top: 0;
}

.site-header-container .site-actions {
  top: 4px;
}

h5.site-secondary-header {
  font-size: 1rem;
  color: #aaa;
  padding-bottom: 0.4375em;
  border-bottom: 1px solid #DCE3EB;
  margin-bottom: 0.35em;
}

.site-container hr {
  color: #DCE3EB;
  margin-bottom: 1.5em;
}

.site-breadcrumb li{
  display: inline;
  font-size: 1rem;
  color: #aaa;
}

.site-breadcrumb ol, ul {
  padding-left: 0;
}

.site-breadcrumb span {
  position: relative;
  top: 6px;
  padding: 0em 0.4em;
}
.site-actions .cog.icon {
  font-size: 1rem;
}
.site-breadcrumb {
  display: none;
}
.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
  position: relative;
  padding-left: 2.871429em!important;
  padding-right: 1.5em!important;
}
.device-hub-title-icon {
  padding-right: 1rem;
}

.station-icon {
  float: left;
}
.menu .item svg {
  margin-right: 10px;
}

.no-data {
  text-align: center;
  color: #aaa;
}

.device-select {
  display: none;
}

.left-sidebar-list .faulted-icon {
  float: right;
  position: relative;
  top: 1px;
}