.widget-legend-item {
  margin-bottom: 5px;
}
.widget-legend-text {
  font-size: 12px;
  color: #555;
  margin-left: 0.8125em;
  position: relative;
  top: 1px;
}
.widget-legend-icon {
  border-radius: 4px;
}