.swyched-select {
  position: relative;
  overflow: visible;
  border: solid #C1C5C8 1px;
  height: 33px;
  border-radius: 3px;
}

.swyched-select-selector {
  position: absolute;
  height: 33px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.swyched-select-selector, 
.swyched-select-selector * {
  user-select: none;
  outline-color: #175785;
}

.swyched-select-value-display {
  position: absolute;
  left: 0;
  top: 50%;
  right: 24px;
  overflow: hidden;
  transform: translateY(-50%);
}

.swyched-select-selector-button {
  position: absolute;
  display: inline-block;
  top: 0%;
  right: 6px;
  bottom: 2px;
  width: 24px;
  overflow: hidden;
}

.swyched-select-selector-button > span {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  background:rgba(255, 255, 255, 0.1);
  color: #555;
}

.swyched-select-dropdown {
  z-index: 9000;
  position: absolute;
  background: rgba(255,255,255,1);
  border: solid #C1C5C8 1px;
  top: 100%;
  min-width: 100%;
  overflow: auto;
  max-height: 300px;
  box-shadow: rgb(255 255 255 / 90%) 0px 1px 0px inset,
    rgb(0 0 0 / 3%) 0px -1px 0px inset,
    rgb(0 0 0 / 20%) 0px 1px 3px;
  border-radius: 0px 0px 4px 4px;
}
.swyched-select-dropdown label {
  margin-bottom: 0;
  font-size: 0.875rem;
}

.swyched-option-container {
  position: relative;
}

.swyched-option-container.selected {
  background-color: rgba(0, 0, 0, 0.04);
}

.swyched-option {
  position: relative;
  cursor: pointer;
  color: #555;
  font-size: 1rem;
  padding: 0px 11px;
}

.swyched-select-dropdown .swyched-option {
  padding: 5px 11px;
}

.swyched-select-dropdown > .swyched-option-container:hover {
  background: rgba(0, 0, 0, 0.06);
}
.select-list-controls {
  color: #aaa;
  padding: 10px 10px 0px 10px;
  display: block;
  font-size: 0.813rem;
}
.select-list-controls button {
  text-decoration: underline;
}

.select-site-header {
  position: relative;
  left: 5px;
  top: -1px;
  color: #555;
  font-size: 0.875rem;
}

.swyched-select-dropdown label{
  padding: 0;
  margin-top: 5px;
}

.host-in-body-host {
  position: fixed;
  left: 0;
  top: 0;
  overflow: visible;
  z-index: 60000;
}
.modal-body .swyched-option label span,
.swyched-input-error-icon {
  line-height: 24px;
}

.modal-body .swyched-select-dropdown label span {
  line-height: 22px;
}