@import '../../Variables.scss';

.modal-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.7);
  cursor: default;
  z-index: 256;
}

.modal-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: white;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
  width: 450px;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.75em 2.5em;
  border-bottom: 1px solid #DCE3EB;
}

.modal-title-text {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: $appFontRegular;
  font-size: 1.25rem;
  color: #555;
}

.modal-controls {
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.6em;
  border-top: 1px solid #DCE3EB;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5em 2.5em 1.5em 2.5em;
  max-height: 385px;
  overflow: auto;
  color: #5b5b60;
  
}

.modal-tab-header {
  background: #f8f8f8;
  color: #555;
  padding: 0.88em 2.5em;
  margin-left: -36px;
  margin-right: -35px;
  margin-bottom: 15px;
  font-size: 1rem;
}

.modal-body h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding-bottom: 0;
  margin-top: 0;
}


.modal-controls-close {
  padding: 2em 1.25em 1.25em 2em;
  color: #aaa;
  cursor: pointer;
}

.modal-controls-close:hover svg {
  fill: #555;
}

.modal-body span {
  font-size: 1rem;
}
.modal-body label span {
  line-height: 28px;
}
.modal-body p {
  color: #555;
  font-size: 16px;
  padding-top: 5px;
  line-height: 21.6px;
}

.modal-footer .btn i.material-icons {
  position: relative;
  top: 0px;
  padding-right: 0px;
  margin-left: 5px;
}

.modal-footer .btn-back {
  padding: .5rem 0.4rem;
  border: none;
}
.modal-footer .scan-new-btn {
  margin-right: 10px;
}

.modal-footer .btn-back:hover,
.modal-footer .btn-back:active {
  color: #666;
  background: none;
  border: none;
}

.modal-footer .btn {
  font-size: 0.875rem;
}
.modal-body .tab-control-container .tab-tab {
  width: 50%;
  text-align: center;
  margin-bottom: 0;
}
.modal-overlay .tab-body {
  background: none;
  padding: 0;
  margin-top: 15px;
}

.modal-footer>* {
  margin: 0rem;
}
h4.modal-sub-heading {
  color: #555;
  font-size: 1.188rem;
  padding-top: 20px;
}

.modal-frame.modal-force-fit {
  height: 600px;
  max-height: 100%;
}

.modal-frame.modal-force-fit .modal-header,
.modal-frame.modal-force-fit .modal-footer,
.modal-frame.modal-force-fit .modal-body {
  position: absolute;
  left: 0;
  right: 0;
  max-height: min-content;
}
.modal-frame.modal-force-fit .modal-header {
  height: 45px;  
}
.modal-frame.modal-force-fit .modal-body {
  position: absolute;
  margin-top: 60px;
  margin-bottom: 80px;
  max-height: 100%;
  bottom: 0px;
  top: 0;
  padding-top: 4px;
  padding-bottom: 6px;
  padding: 1.5em 2.5em 1.5em 2.5em;
}
.modal-frame.modal-force-fit .modal-footer {
  bottom: 12px;
  height: 45px;
}

h4 {
  font-size: 24px;
  color: #555;
  padding-bottom: 20px;
  margin-top: 35px;
}

.organization-modal-container
 div {
  margin-bottom: 0;
}

.modal-confirm-next-icon {
  font-size: 1.25rem;
  position: relative;
}

.modal-body span.input-desc {
  color: #aaa;
  display: block;
  font-size: 12px;
  line-height: 18px;
  padding-top: 0.2em;
  
}