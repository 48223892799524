.user-management .tab-control-container {
  margin-top: 38px;
}
.user-management .tab-tab {
  padding: 5px;
  margin-right: 10px;
}

.user-management .tab-tab span {
  top: 0;
  font-size: 1.25rem;
  margin-left: 0;
  color: #aaa;
  padding: 0em 1.6em;
}
.user-management .tab-tab.active-tab {
  background: #fff;
}
.active-tab.tab-tab span {
  color: #175785;
}
.user-management .tab-tab.user-management-account-tab {
  padding-left: 0;
}

.user-management .tab-body {
  background: #fff;
  padding: 20px 0px;
}
.user-management .form-switch {
  float: left;
  margin-right: 20px;
}
.user-management .form-switch-label {
  padding-top: 5px;
  font-size: 1rem;
  
}
.user-management .tab-control-tabs {
  box-shadow: none;
  border-bottom: 1px solid #DCE3EB;
}
.user-management h4 {
  font-size: 24px;
  color: #555;
  padding-bottom: 20px;
  margin-top: 35px;
}

.user-management .text-link {
  font-size: 16px;
  text-decoration: underline;
}

.text-link.warning-link {
  color: #e23230;
  margin-left: 0;
}
.warning-link span {
  position: relative;
  top: -2px;
  padding-right: 10px;
}
.notification-destination-config {
  border-bottom: 1px solid #DCE3EB;
  padding-top: 15px;
  padding-bottom: 15px;
}
.notification-destination-config input {
  margin-right: 22px;
  border: 1px solid #C1C5C8;
  border-radius: 3px;
}
.label-detail {
  color: #bbb;
  display: block;
  margin-left: 41px;
}
.notification-destination-config label {
  font-size: 1rem;
  
  padding-bottom: 20px;
}
.user-save-all-btn {
  margin-left: 10px;
}

.user-select {
  display: none;
  margin-top: 10px;
  margin-bottom: 30px;
  height: 33px;
}

.user-email-input,
.user-phone-input {
  margin-right: 10px;
  padding-left: 5px;
  border: 1px solid #bbb;
  border-radius: 2px;
  height: 33px;
}
.user-account-tab label {
  color: #aaa;
}

.user-account-tab .form-control {
  padding: 1.1rem 0.9rem;
  max-width: 330px;
}
.user-account-tab .form-select {
  max-width: 330px;
}
.user-management .card-body-page-title {
  color: #555;
  font-size: 1.938rem;
  margin-left: -3px;
}

.left-sidebar-list .swyched-spinner {
  font-size: 40px;
  color: #ccc;
}
.user-management .tab-tab .user-notification-header-title .beta-tag {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-family: "GalanoGrotesqueMedium", sans-serif;
  padding: 0px 6px;
  margin-left: 10px;
  line-height: 22px;
  top: 2px;
}

.user-management .tab-tab span span {
  padding: 0;
}

.notification-destination-config .beta-tag {
  position: relative;
  top: -2px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.user-account-tab h5 {
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.user-account-tab label {
  color: #555;
  font-size: 16px;
}
.user-account-tab label div{
  color: #aaa;
  font-size: 12px;
  margin-left: 36px;
}

.user-account-tab .form-check-input {
  margin-right: 20px;
}
.user-management .tab-control-tabs {
  padding-left: 0;
}