@import '../Variables.scss';


.filter-by-site-container {
  margin-top: 45px;
}

.filter-by-site {
  color: #555;
  line-height: 20px;
}

.filter-by-site-btn {
  padding: 3px 5px;
  font-size: 1rem;
  height: 30px;
  margin-right: 4px;
  border-radius: 5px;
  padding-right: 10px;
  line-height: 150%;
}

.filter-by-site-btn svg {
  margin-top: -3px;
  margin-right: 5px;
}

.filter-by-site-btn:active {
  background: rgba(23, 87, 133, 0.1);
  color: #175785;
}

.filter-by-site-btn:hover {
  background: rgba(193,197,200,.15);
  color: #555;
}

.filter-by-site-btn:hover path {
  fill: #555;
}
button.filter-by-site-btn:focus,
button.filter-by-site-btn:active {
  background: rgba(23, 87, 133, 0.1);
  color: #175785;
}


.filter-by-site-btn:active svg path,
.filter-by-site-btn:focus svg path {
  fill: #175785;
}

.filter-tag  {
  display: inline-block;
  background: rgba(23, 87, 133, 0.1);
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 14px;
  color: #175785;
  transition: all 0.5s ease 0s;
  font-family: $appFontMedium;
}

.filter-by-site .filter-tag {
  margin-right: 15px;
  height: 31px;
  padding: 6px 15px;
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 10px;
}

.filter-by-site .filter-tag svg {
  margin-left: 5px;
  margin-top: -3px;
}

span.clear-filter-btn {
  background: none;
  font-size: 14px;
  color: #aaa;
  cursor: pointer;
  display: inline-block;
}


span.clear-filter-btn:hover {
  text-decoration: underline;
}

.filter-by-site .form-check-input{
  width: 1.25em;
  height: 1.25em;
}

.filter-by-site .select-site-header {
  font-size: 1rem;
  line-height: 28px;
  left: 18px;
}

.filter-by-site .select-list-controls {
  font-size: 1rem;
  padding: 10px 10px 10px 10px;
}

.filter-by-site label {
  padding: 0.2em 0em;
}

.filter-by-site .form-check-input[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' /%3e%3c/svg%3e");
  border-radius: 3px;
}
.inline {
  display: inline;
}

.fadeinout-enter {
  opacity: 0;
}
.fadeinout-enter-active {
  opacity: 1;
  transition: opacity 1s;
}
.fadeinout-exit {
  opacity: 1;
}
.fadeinout-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

