
@media screen and (max-width: 1200px) {
  .header-container {
    height: 65px;
  }
  .top-nav.tab-control-tabs {
    display: none;
  }
  .mobile-nav-toggle {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .org-select-container {
    padding: 0px 10px;
    top: 0;
    right: 0;
    padding-top: 6px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }

  .notification-btn {
    right: 0px;
  }

  /* nav {
    visibility: visible;
  } */
  
  .app-sidebar {
    display: none;
  }
  h2.sheet-title { 
    display: block;
  }

  .top-nav {
    display: none;
  }

  .tab-body {
    top: 0px;
    padding: 2em;
    padding-bottom: 75px;
  }

  .drawer {
    width: 80%;
  }
  .modal-body nav {
    display: none;
  }
  .page-title-container {
    float: left;
    padding: 3em 0em 3em 0em;
    max-width: 100%;
    clear: left;
  }
  .sheet-control-container .btn {
    float: left;
    margin-bottom: 2em;
  }
  .user-management .tab-tab span {
    font-size: 1.35rem;
  }
  .row.payment-integration-cards {
    padding-top: 0em;
  }
  .ant-dropdown .notification-container {
    width: 94%;
    margin: 0 auto;
    margin-top: 15px;
  }

}
@media screen and (max-width: 640px) {
  .card-body {
    padding: 10px;
  }

  .modal-frame {
    width: 90%;
  }

  .payment-plan-container .card-body,
  .access-control .card-body.no-top-padding {
    padding-top: 0;
  }
  .left-side-bar-filter {
    display: none;
  }
  .user-select,
  .access-control-group-select,
  .device-select {
    display: block;
  }

  .report .pagination-control-container {
    position: relative;
    top: 0px;
  }
  .sheet-control-container {
    clear: both;
    padding-top: 0;
  }
  .page-title-container {
    clear: left;
  }

  /* devices */
  .add-device-btn-container .add-device-btn {
    display: none;
  }
  h2.site-title {
    font-size: 25px;
  }
  .site-container .card-body {
    padding: 3em;
    padding-top: 2.3em;
  }
  .device-hub {
    padding: 10px;
    padding-left: 10px;
    padding-top: 25px;
  }
  .ui.dropdown .menu>.item.mobile-site-option.mobile-site-option {
    display: block;
  }
  .site-actions .btn-action {
    margin-top: -5px;
  }
  
  /* users */
  .user-management .card-body-page-title {
    font-size: 1.7rem;
  }

  .user-management .left-sidebar-list {
    display: none;
  }

  .user-management .tab-tab span {
    font-size: 1rem;
  }
  /* pricing */
  .pricing-tabs .tab-tab {
    color: #aaa;
    padding: 12px 20px 12px 20px;
  }
  .pricing-tabs .tab-tab span {
    font-size: 16px;
    top: 0;
  }
  .pricing-tabs .card-body {
    padding: 30px;
  }
  .pricing-tabs .tab-body {
    padding: 3em 0em;
  }
  /* Report */
  .report-summary-item {
    font-size: 13px;
  }

}

@media screen and (max-width: 600px) {  
  table {
    border: 0;
  }
  .recharts-wrapper {
    margin : 0 auto;
  }

  table caption {
    font-size: 1.3em;
  }

  .modal-frame {
    width: 90% !important;
  }

  /* .list-group-horizontal {
    flex-direction: column;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 1px solid #C1C5C8;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #C1C5C8;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  } */
  .search-payment-plan {
    width: 100%;
  }
}