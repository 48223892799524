.react-grid-layout {
  margin-left: -30px;
  margin-right: -30px;
  position: relative;
  top: -30px;
}
.react-grid-item {
  background: #fff;
  box-shadow: 0px 10px 80px -30px rgba(0, 58, 93, 0.25);
  border-radius: 5px;
  padding: 40px;
  padding-top: 35px;
  /* margin: 20px; */
}

.widget-card {
  background: #fff;
  box-shadow: 0px 10px 80px -30px rgba(0, 58, 93, 0.25);
  border-radius: 5px;
  padding: 40px;
  padding-top: 35px;
  /* margin: 20px; */
}

.react-draggable-dragging {
  opacity: 0.8
}
h4.widget-header {
  color: #555;
  padding-bottom: 10px;
  font-family: 'GalanoGrotesqueRegular';
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
}
h4.widget-header span {
  color: #aaa;
  font-size: 16px;
  display: block;
  position: absolute;
  line-height: 21.6px;
  margin-top: 5px;
}

.recharts-default-tooltip {
  background: #fff !important;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid #dce3eb !important;
  box-shadow: 0 10px 25px -8px rgb(0 58 93 / 20%);
}
.recharts-tooltip-item {
  color: #555 !important;
  font-family: 'GalanoGrotesqueRegular';
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  visibility: hidden;
}

.react-grid-item:hover > .react-resizable-handle.react-resizable-handle-se {
  visibility: visible;
}

.react-grid-item.react-grid-placeholder {
  background: rgba(23, 87, 133, 0.4);
}

.react-grid-item {
  cursor: pointer;
}