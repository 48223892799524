@import '../Variables.scss';

.user-notification-container label {
  margin-bottom: 0;
}
.notification-container {
  background: #fff;
  box-shadow: 0px 10px 80px -30px rgba(0, 58, 93, 0.25);
  border-radius: 5px;
  width: 544px;
  min-height: 312px;
  max-height: 571px;
  overflow: auto;
  margin-top: 14px;
}

.notification-container-body {
  height: 100%;
  width: 100%;
}

.notification-drawer-empty {
  font-size: 20px;
  text-align: center;
  color: #aaa;
  position: relative;
  padding-top: 2.2em;
}

.notification-timeline-header {
  color: #aaa;
  padding: 1.5em 1.2em 0.2em 2.5em;
  text-transform: uppercase;
  font-size: 1rem;
}

.notification-item {
  display: block;
  height: auto;
  padding: 1.6em 0em;
  padding-bottom: 0;
  background: #fff;
  position: relative;
}

.notification-item:hover {
  background: rgba(193, 197, 200, 0.15);
  border-radius: 4px;
  cursor: pointer;
}

.notification-item-inner {
  margin: 0px 40px;
  border-bottom: 1px solid #DCE3EB;
  padding-bottom: 20px;
}
.notification-item-indicator {
  position: absolute;
  left: 20px;
  top: 18px;
}
.notification-item .dropdown svg {
  color: #555;
  background: rgba(193, 197, 200, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  border-radius: 3px;
}


.notification-item:hover .dropdown svg {
  visibility: visible;
  fill: #555;
  padding: 5px;
}

.notification-item .dropdown .item svg {
  display: inline;
  visibility: visible;
  padding: 0;
  background: none;
}

p.notification-item-detail {
  color: #555;
  font-size: 15px;
  max-width: 81%;
  overflow: hidden;
  margin-left: 40px;
}

span.notification-item-date-time {
  color: #aaa;
  font-size: 12px;
  display: block;
  
}

.notification-item .ui.dropdown {
  float: right;
  color: #C1C5C8;
  font-size: 0.938rem;
  border-radius: 2px;
  right: 40px;
  position: absolute;
  z-index: 2;
}

.notification-drawer-empty div {
  font-size: 2.5em;
  color: #aaa;
  margin: 0 auto;
  text-align: center;
  display: block;
  min-height: 85px;
}
.notification-drawer-empty div svg {
  overflow: inherit;
}
.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.read-indicator-dot {
  height: 10px;
  width: 10px;
  background-color: $color-status-charging;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
}

.notification-more-detail {
  color: $appTextGrey;
  text-decoration: underline;
}
/* .notification-item-indicator.faulted {
  color: red;
}
.notification-item-indicator.warning {
  color: orange;
} */

button.clear-all {
  color: $appTextGrey;
  display: block;
  position: absolute;
  right: 15px;
  top: 80px;
  text-decoration: underline;
  z-index: 2;
}

button.clear-all span{
  font-size: 16px;
  padding-left: 5px;
  position: relative;
  top: 3px;
}
.show-all-btn {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #aaa;
  position: relative;
  bottom: 0;
  display: none;
  font-size: 16px;
}

.show-all-btn:hover {
  background: rgba(0,0,0,0.05);
}

.show-all-btn span {
  position: relative;
  top: -1px;
  left: 2px;
  margin-left: 5px;
}
.notification-drawer-heading {
  font-size: 20px;
  padding-top: 28px;
  padding-left: 40px;
}

.notification-state-controls {
  margin-top: 16px;
  padding-left: 25px;
  border-bottom: 1px solid #DCE3EB;
}

.all-notifications-btn {
  margin-right: 10px;
}

.notification-state-controls button {
  padding: 10px 15px 5px 15px;
  border-radius: 2px;
  font-size: 1rem;
  color: $appTextGrey;
  padding-left: 16px;
}

.notification-state-controls button.selected,
.notification-state-controls button.selected span {
  color: #175785;
}
.notification-state-controls button.selected {
  border-bottom: 2px solid #175785;
  position: relative;
  top: 1px;
}
.unread-notification-count {
  padding: 1px 10px;
  background: rgba(23, 87, 133, 0.1);
  color: #175785;
  font-size: 1rem;
  text-align: center;
  margin-left: 12px;
  border-radius: 3px;
}

.clear-all {
  float: right;
  top: 40px;
  right: 20px;
  color: #aaa;
  font-size: 20px;
}